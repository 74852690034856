/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button, Image, Text, Divider } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="--style3 --full" anim={""} name={"uvod"} animS={"3"} style={{"backgroundColor":"var(--color-custom-2)"}} border={""} fullscreen={true} css={css`
      background-position: 55% 6%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 --full flex--center" anim={null} style={{"maxWidth":1450}} columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="js-anim  --anim4 --anim-s3 pb--0 pl--0 pr--0 pt--0" anim={"4"} animS={"3"} style={{"maxWidth":850}}>
              
              <Title className="title-box title-box--center fs--86 w--900 title-box--invert" style={{"maxWidth":1450,"paddingLeft":0}} content={"Tvorba a úpravy<br>e-shopů na Shoptetu<br>"}>
              </Title>

              <Button className="btn-box btn-box--shape4 fs--24 mt--30" content={"Zobrazit portfolio"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--30 pt--30" name={"xygq8xn5g4k"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"Co pro Vás děláme ?"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-4qhsb1 --style2 --full pb--60 pt--60" name={"sluzby"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/168/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/168/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/168/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/168/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/168/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/168/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/168/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/168/fullscreen_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" columns={"3"}>
            
            <ColumnWrapper className="--shape5 pb--30 pl--30 pr--30 pt--30" style={{"backgroundColor":"var(--white)"}}>
              
              <Image src={"https://cdn.swbpg.com/o/11212/2d1925b3d1944911af33dd88cd1749bf.svg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":42}} srcSet={""}>
              </Image>

              <Title className="title-box" content={"Grafika a design"}>
              </Title>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

              <Button className="btn-box btn-box--hvr5 btn-box--pbtn3" content={"Prozkoumat<br>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5 pb--30 pl--30 pr--30 pt--30" style={{"backgroundColor":"var(--white)"}}>
              
              <Image src={"https://cdn.swbpg.com/o/11212/2d1925b3d1944911af33dd88cd1749bf.svg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":42}} srcSet={""}>
              </Image>

              <Title className="title-box fs--43" content={"Shoptet od<br>A do Z"}>
              </Title>

              <Text className="text-box text-box--center" content={"Jedná se o nejčastější formu spolupráce s našimi zákazníky. V případě projektu od A do Z dostanete funkční e-shopové řešení, s kterým ihned prodávat. Včetně zaškolení se Shoptetem v ceně projektu."}>
              </Text>

              <Button className="btn-box btn-box--hvr5 btn-box--pbtn3" content={"Prozkoumat<br>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5 pb--30 pl--30 pr--30 pt--30" style={{"backgroundColor":"var(--white)"}}>
              
              <Image src={"https://cdn.swbpg.com/o/11212/2d1925b3d1944911af33dd88cd1749bf.svg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":42}} srcSet={""}>
              </Image>

              <Title className="title-box" content={"Převod dat"}>
              </Title>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

              <Button className="btn-box btn-box--hvr5 btn-box--pbtn3" content={"Prozkoumat<br>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5 pb--30 pl--30 pr--30 pt--30" style={{"backgroundColor":"var(--white)"}}>
              
              <Image src={"https://cdn.swbpg.com/o/11212/2d1925b3d1944911af33dd88cd1749bf.svg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":42}} srcSet={""}>
              </Image>

              <Title className="title-box" content={"Získávání dat"}>
              </Title>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

              <Button className="btn-box btn-box--hvr5 btn-box--pbtn3" content={"Prozkoumat<br>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5 pb--30 pl--30 pr--30 pt--30" style={{"backgroundColor":"var(--white)"}}>
              
              <Image src={"https://cdn.swbpg.com/o/11212/2d1925b3d1944911af33dd88cd1749bf.svg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":42}} srcSet={""}>
              </Image>

              <Title className="title-box title-box--center fs--43" style={{"maxWidth":1000}} content={"Překlady a jazykové mutace<br>"}>
              </Title>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

              <Button className="btn-box btn-box--hvr5 btn-box--pbtn3" content={"Prozkoumat<br>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5 pb--30 pl--30 pr--30 pt--30" style={{"backgroundColor":"var(--white)"}}>
              
              <Image src={"https://cdn.swbpg.com/o/11212/2d1925b3d1944911af33dd88cd1749bf.svg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":42}} srcSet={""}>
              </Image>

              <Title className="title-box fs--43" content={"Kódování"}>
              </Title>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

              <Button className="btn-box btn-box--hvr5 btn-box--pbtn3" url={"/"} href={"/kodovani"} content={"Prozkoumat<br>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" anim={null} name={"informace"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" anim={""} animS={"3"} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box lh--14" content={"Zajišťujeme komplexní služby pro e-shopová řešení na Shoptetu.<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--50 pt--50" name={"o-mne"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --left el--2 flex--center" anim={"2"} animS={"3"} style={{"maxWidth":1200}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":453}}>
              
              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36255/fbc56f0a921047f78495831074f6341d_s=350x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/36255/fbc56f0a921047f78495831074f6341d_s=350x_.png 350w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Title className="title-box title-box--left fs--62" content={"O mně"}>
              </Title>

              <Text className="text-box text-box--left fs--18" style={{"maxWidth":650}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, změnit velikost řádkování, zarovnat text na stranu a dokonce nastavit maximální šířku textu v pixlech."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"reference"} style={{"backgroundColor":"var(--color-custom-2)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: var(--white);\">Reference</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 mt--25" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image className="--shadow4 --center" alt={""} src={"https://cdn.swbpg.com/t/36255/c519780b8cbd4987980eb3707adda01b_s=860x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/36255/c519780b8cbd4987980eb3707adda01b_s=350x_.png 350w, https://cdn.swbpg.com/t/36255/c519780b8cbd4987980eb3707adda01b_s=660x_.png 660w, https://cdn.swbpg.com/t/36255/c519780b8cbd4987980eb3707adda01b_s=860x_.png 860w, https://cdn.swbpg.com/t/36255/c519780b8cbd4987980eb3707adda01b_s=1400x_.png 1400w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box title-box--left fs--62 mt--02" content={"<span style=\"color: var(--white);\">KapkaPřírody.eu</span>"}>
              </Title>

              <Text className="text-box text-box--left mt--16 pb--0 pl--0 pr--0 pt--0" content={"<span style=\"color: var(--white);\">Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit vešlikost písma, řádkování, nebo zarovnat text.</span>"}>
              </Text>

              <Divider style={{"backgroundColor":"var(--white)"}}>
              </Divider>

              <Image className="--left" alt={""} src={"https://cdn.swbpg.com/t/36255/f2a4b51b003747038cf8c69aaa8c5eb2_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":548}} srcSet={"https://cdn.swbpg.com/t/36255/f2a4b51b003747038cf8c69aaa8c5eb2_s=350x_.png 350w, https://cdn.swbpg.com/t/36255/f2a4b51b003747038cf8c69aaa8c5eb2_s=660x_.png 660w, https://cdn.swbpg.com/t/36255/f2a4b51b003747038cf8c69aaa8c5eb2_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box title-box--right fs--62" content={"<span style=\"color: var(--white);\">Only4Cars.cz</span>"}>
              </Title>

              <Text className="text-box text-box--right mt--25 pb--0 pl--0 pr--0 pt--0" content={"<span style=\"color: var(--white);\">Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit vešlikost písma, řádkování, nebo zarovnat text.</span>"}>
              </Text>

              <Divider style={{"backgroundColor":"var(--white)"}}>
              </Divider>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image className="--center" alt={""} src={"https://cdn.swbpg.com/t/36255/c519780b8cbd4987980eb3707adda01b_s=860x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/36255/c519780b8cbd4987980eb3707adda01b_s=350x_.png 350w, https://cdn.swbpg.com/t/36255/c519780b8cbd4987980eb3707adda01b_s=660x_.png 660w, https://cdn.swbpg.com/t/36255/c519780b8cbd4987980eb3707adda01b_s=860x_.png 860w, https://cdn.swbpg.com/t/36255/c519780b8cbd4987980eb3707adda01b_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Button className="btn-box btn-box--shape4 fs--22 mt--08" content={"Zobrazit portfolio"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--20 pt--30" name={"kontakt"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --shape5 --center el--1 pb--50 pt--50 flex--center" style={{"backgroundColor":"var(--white)"}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":595}}>
              
              <Title className="title-box fs--62" content={"Ozvěte se nám"}>
              </Title>

              <Button className="btn-box btn-box--shape4 fs--22 mt--16" content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--20 pt--20" name={"paticka"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left fs--20" content={"Studio Digital &lt;br&gt; Adam Zgabaj"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--right fs--20" content={"Vytvořeno přes <a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}